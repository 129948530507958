<template>
    <RealtimeSystem
        :key="renderCount"
        :componentKey="componentKey"
        :onAnyEventsSocketScheme="onAnyEventsSocketScheme"
        @failedConnect="failedConnect"
        @endMatch="endMatch"
    />
</template>

<script>
import RealtimeSystem from '../../MapV2/RealtimeSystem/RealtimeSystem.vue';
export default {
    name: 'ReconciliationImportRealtimeSystem',
    components: {RealtimeSystem},
    data: () => ({
        componentKey: 'ReconciliationImportRealtimeSystem',
        onAnyEventsSocketScheme: [
			{
				id: 'endMatch',
				eventNameSocket: 'match_queue_job',
				emitNameComponent: 'endMatch',
			},
		],
        renderCount: 0
    }),
    methods: {
        failedConnect() {
            console.log('failedConnect');
            this.renderCount += 1;
        },
        endMatch(data) {
            const {success = false} = data;
            
            this.$emit('endMatch', Boolean(success));
        }
    }
}
</script>