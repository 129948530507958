<template>
    <div class="reconciliation-import-marking">
        <div class="reconciliation-import-marking__section">
            <BaseButton
                view="border"
                @click="toPrevStep"
            >
                Назад
            </BaseButton>
        </div>
        <!-- <div class="reconciliation-import-marking__section">
            <ReconciliationInfo :infoText="tabDescription" />
        </div> -->
        <!-- <div class="reconciliation-import-marking__section">
            <div class="reconciliation-import-marking__title">
                Настройки шаблона
            </div>
            <div class="reconciliation-import-marking__subtitle">
                Выберите шаблон
            </div>
            <BaseSelect
                :options="templates"
                :value="templateIdActive"
                defaultTitle="Выберите из списка" 
                class="reconciliation-import-marking__select"
                @check="onSelectTemplate"
            />
        </div> -->
        <div class="reconciliation-import-marking__section">
            <div class="reconciliation-import-marking__title">
                Настройки импорта
            </div>
            <div class="reconciliation-import-marking__setting">
                <div
                    v-for="(setting) in settings"
                    :key="getSettingId(setting)"
                    class="reconciliation-import-marking__setting-item"
                >
                    <div class="reconciliation-import-marking__subtitle">
                        {{getSettingSubtitle(setting)}}
                    </div>
                    <BaseSelect
                        :options="getSettingList(setting)"
                        :value="getSettingListIdActive(setting)"
                        :canSearch="true"
                        :isVirtualSearch="false"
                        defaultTitle="Выберите из списка" 
                        class="reconciliation-import-marking__select"
                        @check="onSelectSetting($event, setting)"
                        @lazyload="onLazyloadSetting(setting)"
                        @onSearchValue="onSearchSetting($event, setting)"
                    />
                </div>
            </div>
        </div>
        <div class="reconciliation-import-marking__section">
            <div class="reconciliation-import-marking__title">
                Разметка данных из файла
            </div>
            <MenuTabList
                :tabList="tabList"
                :tabIdActive="tabIdActive"
                tabMode="default"
                class="reconciliation-import-marking__menu-tab-list"
                @onClickTab="onClickTab"
            />
            <!-- <div class="reconciliation-import-marking__subtitle">
                Номер строки в файле с заголовками
            </div>
            <BaseCounter
                :minCount="1"
                :maxCount="10" 
            /> -->
            <BaseButton
                view="simple"
                @click="onClearSelected"
            >
                Очистить весь выбор данных
            </BaseButton>
            <ReconciliationImportMarkingTable 
                :columns="tableColumns"
                :rows="tableRows"
                :markingValues="markingValues"
                class="reconciliation-import-marking__table"
                @onSelectItemsTable="onSelectItemsTable"
                @onToggleSelectModal="onToggleSelectModal"
            />
        </div>
    </div>
</template>

<script>
import MenuTabList from '../../MenuTabList/MenuTabList.vue';
// import ReconciliationInfo from './ReconciliationInfo.vue';
import ReconciliationImportMarkingTable from './ReconciliationImportMarkingTable.vue';
import BaseButton from '../../Base/BaseButton.vue';
import BaseSelect from '../../Base/BaseSelect.vue';
// import BaseCounter from '../../Base/BaseCounter.vue';
export default {
    name: 'ReconciliationImportMarking',
    components: {
        MenuTabList,
        // ReconciliationInfo,
        ReconciliationImportMarkingTable,
        BaseButton,
        BaseSelect,
        // BaseCounter
    },
    props: {
        templates: {
            type: Array,
            default: () => ([])
        },
        templateIdActive: {
            type: String,
            default: ''
        },
        settings: {
            type: Array,
            default: () => ([])
        },
        tabList: {
            type: Array,
            default: () => ([])
        },
        tabIdActive: {
            type: String,
            default: ''
        },
        tabDescription: {
            type: String,
            default: ''
        },
        tableColumns: {
            type: Array,
            default: () => ([])
        },
        tableRows: {
            type: Array,
            default: () => ([])
        },
        markingValues: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        toPrevStep() {
            this.$emit('toPrevStep')
        },
        onSelectTemplate(template = {}) {
            this.$emit('onSelectTemplate', String(template?.id ?? ''))
        },
        onSelectSetting(settingListItem = {}, setting = {}) {
            this.$emit('onSelectSetting', String(settingListItem?.id ?? ''), String(setting?.id ?? ''));
        },
        onLazyloadSetting(setting = {}) {
            this.$emit('onLazyloadSetting', String(setting?.id));
        },
        onSearchSetting(searchValue = '', setting = {}) {
            this.$emit('onSearchSetting', searchValue, String(setting?.id ?? ''));
        },
        onSelectItemsTable(rowId = '', itemsSelected = []) {
            this.$emit('onSelectItemsTable', rowId, itemsSelected);
        },
        getSettingId(setting = {}) {
            return String(setting?.id ?? '')
        },
        getSettingSubtitle(setting = {}) {
            return String(setting?.subtitle ?? '');
        },
        getSettingList(setting = {}) {
            return setting?.list ?? [];
        },
        getSettingListIdActive(setting = {}) {
            return String(setting?.listIdActive ?? '');
        },
        onClickTab(tabId = '') {
            this.$emit('onClickTab', tabId);
        },
        onClearSelected() {
            this.$emit('onClearSelected');
        },
        onToggleSelectModal(isOpen = false) {
            this.$emit('onToggleSelectModal', isOpen);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-import-marking {
        &__section {
            margin-bottom: 20px;
        }

        &__title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 10px;
        }

        &__subtitle {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            margin-bottom: 5px;
        }

        &__select {
            width: 210px;
        }

        &__setting {
            display: flex;

            &-item {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }

        &__table {
            margin-top: 20px;
        }

        &__menu-tab-list {
            margin-bottom: 10px;
        }
    }
</style>