<template>
    <AppTable 
        primaryKey="id" 
        :columns="tableColumns"
        :rows="tableRows"
    >
        <template
            v-for="(column) in tableColumnsSlot"
            v-slot:[column]="slotProps"

        >
            <div
                :key="column" 
                :class="{'reconciliation-import-download-table__cell_error': getRowIsError(slotProps)}"
                v-tooltip="getRowError(slotProps)"
            >
                {{getRowValue(slotProps)}}
            </div>
        </template>
    </AppTable>
</template>

<script>
import AppTable from '../../Table2/Table.vue';
export default {
    name: 'ReconciliationImportDownloadTable',
    components: {AppTable},
    props: {
        tableColumns: {
            type: Array,
            default: () => ([])
        },
        tableRows: {
            type: Array,
            default: () => ([])
        },
    },
    computed: {
        tableColumnsSlot() {
            return this.tableColumns.filter(tableColumn => Boolean(tableColumn?.isSlot ?? false)).map(tableColumn => String(tableColumn?.prop ?? ''));
        }
    },
    methods: {
        getRowValue(slotProps = {}) {
            const {cell = {}} = slotProps;
            return String(cell?.value ?? '');
        },
        getRowError(slotProps = {}) {
            const {cell = {}} = slotProps;
            return String(cell?.error ?? '');
        },
        getRowIsError(slotProps = {}) {
            return this.getRowError(slotProps) !== '';
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-import-download-table {
        &__cell {
            &_error {
                color: #F84967;
            }
        }
    }
</style>