<template>
    <div class="reconciliation-import">
        <div class="reconciliation-import__step">
            <ReconciliationImportAttach
                v-if="isImportStepMap.attach"
                :tabDescription="imporDataDescriptionTab"
                :tabList="importDataTabList"
                :tabIdActive="importDataTabIdActive"
                :file="importDataFile"
                :isLoading="isLoading"
                @onClickTab="onClickTab"
                @onChangeFile="onChangeFile"
            />
            <ReconciliationImportMarking
                v-if="isImportStepMap.marking"
                :tabDescription="imporDataDescriptionTab"
                :tabList="importDataTabList"
                :tabIdActive="importDataTabIdActive"
                :tableColumns="importDataTableColumns"
                :tableRows="importDataTableRows"
                :markingValues="importDataMarkingValuesFiltered"
                :templates="importDataTemplates"
                :templateIdActive="importDataTemplateIdActive"
                :settings="importDataSettings"
                @toPrevStep="toPrevStep"
                @onClickTab="onClickTab"
                @onSelectTemplate="onSelectTemplate"
                @onSelectSetting="onSelectSetting"
                @onLazyloadSetting="onLazyloadSetting"
                @onSearchSetting="onSearchSetting"
                @onSelectItemsTable="onSelectItemsTable"
                @onClearSelected="onClearSelected"
                @onToggleSelectModal="onToggleSelectModal"
            />
            <ReconciliationImportDownload
                v-if="isImportStepMap.download"
                :tabDescription="imporDataDescriptionTab"
                :tabList="importDataTabList"
                :tabIdActive="importDataTabIdActive"
                :tableColumns="importDataTableColumns"
                :tableRows="importDataTableRows"
                :isLoading="isLoading"
                @toPrevStep="toPrevStep"
                @onClickTab="onClickTab"
            />
            <ReconciliationImportRealtimeSystem @endMatch="endMatch" />
        </div>
        <div class="reconciliation-import__actions">
            <div class="reconciliation-import__actions-block">
                <template v-if="isImportStepMap.marking">
                    <BasePreloaderSkeleton v-if="isLoading" />
                    <BaseButton
                        view="simple"
                        @click="onSaveTemplate"
                    >
                        Сохранить шаблон
                    </BaseButton>
                </template>
            </div>
            <div class="reconciliation-import__actions-block">
                <div class="reconciliation-import__action">
                    <BasePreloaderSkeleton v-if="isLoading" />
                    <BaseButton
                        view="border"
                        @click="onCancel"
                    >
                        Отменить
                    </BaseButton>
                </div>
                <div class="reconciliation-import__action">
                    <BasePreloaderSkeleton v-if="isLoading" />
                    <BaseButton
                        :disabled="isDisabledNext"
                        view="secondary"
                        @click="toNextStep"
                    >
                        {{nextActionName}}
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReconciliationImportAttach from './ReconciliationImportAttach.vue';
import ReconciliationImportMarking from './ReconciliationImportMarking.vue';
import ReconciliationImportDownload from './ReconciliationImportDownload.vue';
import ReconciliationImportRealtimeSystem from './ReconciliationImportRealtimeSystem.vue';
import BaseButton from '../../Base/BaseButton.vue';
import BasePreloaderSkeleton from '../../Base/BasePreloaderSkeleton.vue';
import ServiceReconciliationImport from '../../../services/ServiceReconciliationV2/ServiceReconciliationImport';
export default {
    name: 'ReconciliationImport',
    components: {
        ReconciliationImportAttach,
        ReconciliationImportMarking,
        ReconciliationImportDownload,
        ReconciliationImportRealtimeSystem,
        BaseButton,
        BasePreloaderSkeleton
    },
    data: () => ({
        isLoading: false,
        isLoadingSearchSetting: false,
        importSteps: ['attach', 'marking', 'download'],
        importStepIndex: 0,
        importData: {
            attach: {
                description: {
                    approved: 'Текст для экрана прикрепления файла предфакта',
                    reconciliation: 'Текст для экрана прикрепления файла сверки'
                },
                tabList: [
                    {
                        id: 'approved',
                        name: 'Предфакт'
                    },
                    {
                        id: 'reconciliation',
                        name: 'Сверка'
                    }
                ],
                tabIdActive: 'approved',
                file: null,
                fileId: ''
            },
            marking: {
                description: {
                    approved: 'Текст для экрана разметки файла предфакта',
                    reconciliation: 'Текст для экрана разметки файла сверки'
                },
                tabList: [],
                tabIdActive: '',
                tableColumns: [],
                tableRows: {},
                markingValues: [],
                templates: [
                    // {
                    //     id: 'tmp1',
                    //     value: 'Шаблон 1'
                    // },
                    // {
                    //     id: 'tmp2',
                    //     value: 'Шаблон 2'
                    // },
                    // {
                    //     id: 'tmp3',
                    //     value: 'Шаблон 3'
                    // }
                ],
                templateIdActive: '',
                settings: [
                    {
                        id: 'cities',
                        subtitle: 'Город',
                        list: [],
                        listIdActive: '',
                        totalCount: 0,
                        page: 1,
                        search: ''
                    },
                    {
                        id: 'suppliers',
                        subtitle: 'Оператор',
                        list: [],
                        listIdActive: '',
                        totalCount: 0,
                        page: 1,
                        search: ''
                    }
                ],
            },
            download: {
                description: {
                    approved: 'Текст для экрана загрузки размеченного файла предфакта',
                    reconciliation: 'Текст для экрана загрузки размеченного файла файла сверки'
                },
                tabList: [
                    {
                        id: 'found',
                        name: 'Найдено'
                    },
                    {
                        id: 'unfound',
                        name: 'Не найдено'
                    }
                ],
                tabIdActive: 'found',
                tableColumns: [],
                tableRows: {},
                tableRowsTotalCount: {}
            }
        },
    }),
    computed: {
        importDataByStep() {
            return this.importData[this.importStep] ?? {};
        },
        imporDataDescriptionTab() {
            const description = this.importDataByStep?.description ?? {};
            return String(description[this.importDataTabIdActiveAttach] ?? '');
        },
        importDataTabList() {
            const tabList = this.importDataByStep?.tabList ?? [];
            return tabList.map(tabListItem => ({
                ...tabListItem,
                isTotalCount: this.isImportStepMap.download,
                totalCount: parseInt(this.importDataTableRowsTotalCount[String(tabListItem?.id ?? '')] ?? 0)
            }));
        },
        importDataTabListIds() {
            return this.importDataTabList.map(tab => String(tab?.id ?? ''));
        },
        importDataTabIdActive() {
            return String(this.importDataByStep?.tabIdActive ?? '');
        },
        importDataTabIdActiveAttach() {
            const importDataAttach = this.importData?.attach ?? {};
            return String(importDataAttach?.tabIdActive ?? '');
        },
        importDataTabIdActiveMarking() {
            const importDataMarking = this.importData?.marking ?? {};
            return String(importDataMarking?.tabIdActive ?? '');
        },
        importDataTableColumns() {
            return this.importDataByStep?.tableColumns ?? [];
        },
        importDataTableRows() {
            const tableRowsByList = this.importDataByStep?.tableRows ?? {};
            return tableRowsByList[this.importDataTabIdActive] ?? [];
        },
        importDataTableRowsIds() {
            return this.importDataTableRows.map(row => String(row?.id ?? ''));
        },
        importDataTableRowsUnfound() {
            const tableRowsByList = this.importDataByStep?.tableRows ?? {};
            const rowsUnfound = tableRowsByList?.unfound ?? [];
            return rowsUnfound;
        },
        importDataMarkingValues() {
            return this.importDataByStep?.markingValues ?? [];
        },
        importDataMarkingValuesFiltered() {
            return this.importDataMarkingValues.filter(markingValue => !this.importDataTableRowsMarkingSelectedIds.includes(String(markingValue?.id ?? '')));
        },
        importDataTableRowsMarkingSelected() {
            const tableRowsMarkingSelected = this.importDataTableRows.reduce((tableRowsMarkingSelected, row) => {
                const {id: rowId = '', markingValue = []} = row;
                markingValue.forEach(markingValueItem => {
                    const {
                        id: markingValueId = '',
                        type: markingValueType = '',
                        dates: markingValueDates = []
                    } = markingValueItem;
                    if (String(markingValueId) !== '') {
                        let tableRowsMarkingSelectedItem = {
                            rowId: String(rowId),
                            markingValueId: String(markingValueId),
                            type: String(markingValueType)
                        };
                        if (String(markingValueType) === 'multi')
                            tableRowsMarkingSelectedItem.dates = markingValueDates;

                        tableRowsMarkingSelected.push(tableRowsMarkingSelectedItem);
                    }
                })

                return tableRowsMarkingSelected;
            }, []);

            return tableRowsMarkingSelected;
        },
        importDataTableRowsMarkingSelectedIds() {
            const importDataTableRowsMarkingSelectedIds = this.importDataTableRowsMarkingSelected.map(rowMarkingSelected => String(rowMarkingSelected?.markingValueId ?? ''));

            return importDataTableRowsMarkingSelectedIds;
        },
        importDataTableRowsTotalCount() {
            return this.importDataByStep?.tableRowsTotalCount ?? {};
        },
        isImportDataTableRowsMarkingCity() {
            return this.importDataTableRowsMarkingSelectedIds.some(rowMarkingSelectedId => String(rowMarkingSelectedId) === 'city');
        },
        isImportDataTableRowsMarkingSupplier() {
            return this.importDataTableRowsMarkingSelectedIds.some(rowMarkingSelectedId => String(rowMarkingSelectedId) === 'supplier');
        },
        isImportDataTableRowsUnfound() {
            return this.importDataTableRowsUnfound.length > 0;
        },
        importDataFile() {
            return this.importDataByStep?.file ?? null;
        },
        importDataFileId() {
            return String(this.importData?.attach?.fileId ?? '');
        },
        importDataTemplates() {
            return this.importDataByStep?.templates ?? [];
        },
        importDataTemplateIdActive() {
            return String(this.importDataByStep?.templateIdActive ?? '');
        },
        importDataSettings() {
            return this.importDataByStep?.settings ?? [];
        },
        importDataSettingsIds() {
            return this.importDataSettings.map(importDataSetting => String(importDataSetting?.id ?? ''));
        },
        importDataSettingsListIdsActive() {
            return this.importDataSettings.map(importDataSetting => ({
                id: String(importDataSetting?.id ?? ''),
                listIdActive: String(importDataSetting?.listIdActive ?? '')
            }));
        },
        isMarkingPrices() {
            return this.importDataTableRowsMarkingSelected.filter(rowMarkingSelected => String(rowMarkingSelected?.type ?? '') === 'multi').length > 0;
        },
        isMarkingCity() {
            return this.getSettingsListIdActive('cities') !== '' || this.isImportDataTableRowsMarkingCity;
        },
        isMarkingSupplier() {
            return this.getSettingsListIdActive('suppliers') !== '' || this.isImportDataTableRowsMarkingSupplier;
        },
        isDisabledNextMap() {
            return {
                attach: this.importDataFile === null,
                marking: !(this.isMarkingPrices && this.isMarkingCity && this.isMarkingSupplier),
                download: this.isImportDataTableRowsUnfound && this.importDataTabIdActiveAttach === 'approved'
            };
        },
        isDisabledNext() {
            return this.isDisabledNextMap[this.importStep] ?? false;
        },
        importStep() {
            return this.importSteps[this.importStepIndex] ?? '';
        },
        isImportStepMap() {
            return {
                attach: this.importStep === 'attach',
                marking: this.importStep === 'marking',
                download: this.importStep === 'download'
            };
        },
        nextActionName() {
            return this.isImportStepMap.download && this.importDataTabIdActiveAttach === 'approved'
                ? 'Загрузить' : 'Далее';
        }
    },
    watch: {
        isImportStepMap() {
            if (this.isImportStepMap.marking) {
                this.getSettings(this.importDataSettingsIds);
                this.getMarkingValues();
            }
        }
    },
    methods: {
        setImportData(importDataKey = '', importDataValue = '', importStep = '') {
            const step = importStep === '' ? this.importStep : String(importStep);
            this.importData = {
                ...this.importData,
                [step]: {
                    ...this.importData[step],
                    [importDataKey]: importDataValue
                }
            };
        },
        onChangeFile(file = null) {
            this.attachFile(file);
        },
        async attachFile(file = null) {
            try {
                this.isLoading = true;
                const fileData = await ServiceReconciliationImport.attachFile(file);
                this.attachFileAfter(fileData);
                this.isLoading = false;
            } catch (error) {
                alert(error);
                this.isLoading = false;
            }
        },
        attachFileAfter(fileData = {}) {
            ['fileId', 'file'].forEach(importDataKey => this.setImportData(importDataKey, fileData[importDataKey] ?? null));
            ['tabList', 'tabIdActive', 'tableColumns', 'tableRows'].forEach(importDataKey => this.setImportData(importDataKey, fileData[importDataKey] ?? null, 'marking'));
        },
        async getSettings(settingsIds = []) {
            try {
                const settingsData = await ServiceReconciliationImport.getSettings(settingsIds);
                this.getSettingsAfter(settingsData);
            } catch (error) {
                console.log(error);
            }
        },
        getSettingsAfter(settingsData = []) {
            const settings = this.importDataSettings.map(importDataSetting => {
                const {id = ''} = importDataSetting;
                const settingDataIndex = settingsData.findIndex(setting => String(setting?.id ?? '') === String(id));
                let setting = {...importDataSetting};
                if (settingDataIndex !== -1) {
                    const {items = [], totalCount = 0, page = 1} = settingsData[settingDataIndex];
                    setting = {
                        ...setting,
                        list: [...importDataSetting?.list, ...items],
                        totalCount,
                        page
                    };
                }

                return setting;
            });

            this.setImportData('settings', settings);

            this.$nextTick(() => this.isLoadingSearchSetting = false);
        },
        onLazyloadSetting(settingId = '') {
            if (!this.isLoadingSearchSetting)
                this.getSetting(settingId);
        },
        onSearchSetting(searchValue = '', settingId = '') {
            this.isLoadingSearchSetting = true;

            let settingsBuffer = [...this.importDataSettings];
            const settingIndex = settingsBuffer.findIndex(importDataSetting => String(importDataSetting?.id) === String(settingId));
            if (settingIndex !== -1) {
                const setting = {
                    ...settingsBuffer[settingIndex],
                    list: [],
                    totalCount: 0,
                    page: 0,
                    search: String(searchValue)
                };

                settingsBuffer.splice(settingIndex, 1, setting);
            }

            this.setImportData('settings', settingsBuffer);

            this.getSetting(settingId);
        },
        async getSetting(settingId = '') {
            const {page = 1, search = '', isCanGet = false} = this.getSettingBefore(settingId);
            if (isCanGet) {
                try {
                    const settingData = await ServiceReconciliationImport.getSetting(settingId, page + 1, search);
                    this.getSettingsAfter([settingData]);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        getSettingBefore(settingId = '') {
            const setting = this.importDataSettings.find(importDataSetting => String(importDataSetting?.id) === String(settingId)) ?? {};
            const isCanGet = setting?.list.length <= setting?.totalCount;
            const params = {
                page: parseInt(setting?.page ?? 1),
                search: String(setting?.search ?? ''),
                isCanGet
            };

            return params;
        },
        async getMarkingValues() {
            try {
                const markingValues = await ServiceReconciliationImport.getMarkingValues(this.importDataTabIdActiveAttach);
                this.setImportData('markingValues', markingValues);
                this.getTemplates();
            } catch (error) {
                console.log(error);
            }
        },
        async getTemplates() {
            try {
                const templates = await ServiceReconciliationImport.getTemplates('import', this.importDataMarkingValues);
                this.setImportData('templates', templates);
                this.updateTemplateAfter();
            } catch (error) {
                console.log(error);
            }
        },
        async runMatch() {
            try {
                this.isLoading = true;
                await ServiceReconciliationImport.runMatch(
                    this.importDataFileId,
                    this.importDataTabIdActive,
                    this.importDataTabIdActiveAttach,
                    this.importDataTableRowsMarkingSelected,
                    this.importDataSettingsListIdsActive,
                    0
                );
            } catch (error) {
                console.log(error);
                alert(error);
                this.toPrevStep();
                this.isLoading = false;
            }
        },
        async getTableDownload() {
            try {
                this.isLoading = true;
                const tableData = await ServiceReconciliationImport.getTableDownload(this.importDataTabListIds, this.importDataFileId, this.importDataTabIdActiveMarking);
                ['tableColumns', 'tableRows', 'tableRowsTotalCount'].forEach(importDataKey => this.setImportData(importDataKey, tableData[importDataKey] ?? null));
                this.isLoading = false;

            } catch (error) {
                console.log(error);
                this.isLoading = false;
            }
        },
        async downloadMatch() {
            try {
                await ServiceReconciliationImport.downloadMatch(this.importDataFileId, this.importDataTabIdActiveMarking);
            } catch (error) {
                console.log(error);
            }
        },
        endMatch(success = false) {
            this.isLoading = false;

            if (success)
                this.getTableDownload();
            else {
                alert('Что-то пошло не так при разметке');
                this.toPrevStep();
            }

        },
        getSettingsListIdActive(settingKey = '') {
            const setting = this.importDataSettingsListIdsActive.find(setting => String(setting?.id ?? '') === String(settingKey));
            return String(setting?.listIdActive ?? '');
        },
        onClickTab(tabId = '') {
            this.setImportData('tabIdActive', String(tabId));
        },
        onSelectTemplate(templateId = '') {
            this.setImportData('templateIdActive', templateId);
        },
        async onSaveTemplate() {
            const actionType = this.importDataTemplates.length > 0 ? 'update' : 'create';
            try {
                const template = await ServiceReconciliationImport.setTemplate({
                    templateName: 'Шаблон',
                    templateAdditingParams: actionType === 'create' ? {type: 'import'} : {template_id: String(this.importDataTemplates[0]?.id ?? '')},
                    rowsMarkingSelected: this.importDataTableRowsMarkingSelected,
                    settingsListIdsActive: this.importDataSettingsListIdsActive,
                    startRowIndex: 0,
                    markingValues: this.importDataMarkingValues,
                    actionType: actionType
                });
                this.setImportData('templates', [template]);
            } catch (error) {
                console.log(error);
            }
        },
        onSelectSetting(listIdActive = '', settingId = '') {
            let settingsBuffer = [...this.importDataSettings];
            const settingsBufferIndex = settingsBuffer.findIndex((setting) => String(setting?.id ?? '') === String(settingId));
            const settingNew = {...settingsBuffer[settingsBufferIndex], listIdActive: String(listIdActive)};
            settingsBuffer.splice(settingsBufferIndex, 1, settingNew);

            this.setImportData('settings', [...settingsBuffer]);
        },
        onCancel() {
            this.$emit('onCancel');
        },
        onSelectItemsTable(rowId = '', itemsSelected = []) {
            let importDataTableRowsBuffer = [...this.importDataTableRows];
            const importDataTableRowsIndex = importDataTableRowsBuffer.findIndex(row => String(row?.id) === String(rowId));
            const importDataTableRowNew = {
                ...importDataTableRowsBuffer[importDataTableRowsIndex],
                markingValue: [...itemsSelected]
            };
            importDataTableRowsBuffer.splice(importDataTableRowsIndex, 1, importDataTableRowNew);
            const importDataTableRowsNew = {
                ...this.importDataByStep?.tableRows,
                [this.importDataTabIdActive]: [...importDataTableRowsBuffer]
            };

            this.setImportData('tableRows', importDataTableRowsNew);

        },
        toNextStep() {
            if (this.isImportStepMap.download && this.importDataTabIdActiveAttach === 'approved') {
                this.downloadMatch();
                this.onCancel();
            }
            else if(this.isImportStepMap.download && this.importDataTabIdActiveAttach === 'reconciliation'){
                this.onCancel();
            }
            else {
                if (this.isImportStepMap.marking){
                    this.runMatch();
                }
                this.importStepIndex += 1;
            }
        },
        toPrevStep() {
            this.importStepIndex -= 1;
        },
        updateTemplateAfter() {
            const template = this.importDataTemplates[0] ?? {};
            const {rowsMarkingValue = {}, settingsValue = {}} = template;
            Object.keys(rowsMarkingValue).forEach(rowId => this.onSelectItemsTable(String(rowId), rowsMarkingValue[rowId] ?? []));
            Object.keys(settingsValue).forEach(settingKey => this.onSelectSetting(String(settingsValue[settingKey]), String(settingKey)));
        },
        onClearSelected() {
            this.importDataTableRowsIds.forEach(rowId => this.onSelectItemsTable(String(rowId), []));
            this.importDataSettingsIds.forEach(settingId => this.onSelectSetting('', String(settingId)));
        },
        onToggleSelectModal(isOpen = false) {
            this.$emit('onToggleSelectModal', isOpen);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-import {
        &__actions {
            display: flex;
            justify-content: space-between;

            &-block {
                display: flex;
            }
        }

        &__action {
            position: relative;
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
</style>
