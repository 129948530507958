<template>
    <div class="base-select-modal">
        <BaseButton
            :active="isOpen"
            class="base-select-modal__button"
            :class="{'base-select-modal__button_default': isPreviewDefault}"
            @click="onToggle"
        >
            {{preview}}
        </BaseButton>
        <BaseModal 
            v-if="isOpen"
            :modalWidth="'820px'"
            @close="onToggle"
        >
            <template v-slot:modalBody>
                <div class="base-select-modal__body">
                    <div class="base-select-modal__section base-select-modal__title">
                        {{title}}
                    </div>
                    <div class="base-select-modal__section base-select-modal__action-list">
                        <BaseButton
                            view="border"
                            class="base-select-modal__action-item"
                            @click="onToggle"
                        >
                            Назад
                        </BaseButton>
                        <BaseButton
                            view="secondary"
                            class="base-select-modal__action-item"
                            @click="onToggle"
                        >
                            Сохранить
                        </BaseButton>
                    </div>
                    <div class="base-select-modal__section">
                        <BaseTextInput
                            :isSearch="true"
                            view="primary"
                            placeholder="Поиск по названию значения"
                            v-model="search"
                        />
                    </div>
                    <div class="base-select-modal__list">
                        <div class="base-select-modal__list-block">
                            <div class="base-select-modal__list-title">
                                Значения
                            </div>
                            <div 
                                v-for="item in itemsSimple"
                                :key="getItemSimpleId(item)"
                                :class="{'base-select-modal__list-simple-item_active': getItemSimpleIsSelected(item)}"
                                class="base-select-modal__list-simple-item"
                                @click="onClickItemSimple(item)"
                            >
                                {{getItemSimpleValue(item)}}
                            </div>
                        </div>
                        <div class="base-select-modal__list-block">
                            <div
                                v-for="group in itemsMultiGroups"
                                :key="getItemMultiGroupKey(group)"
                                class="base-select-modal__list-group"
                            >
                                <div class="base-select-modal__list-title">
                                    {{getItemMultiGroupLabel(group)}}
                                </div>
                                <div
                                    v-for="item in getItemMultiGroupItems(group)"
                                    :key="getItemMultiId(item)"
                                    class="base-select-modal__list-multi-item"
                                >
                                    <div class="base-select-modal__list-multi-item-value">
                                        {{getItemMultiValue(item)}}
                                    </div>
                                    <BaseDropdown
                                        position="left"
                                        :padding="0"
                                        @changeVisible="onToggleDatepicker($event, item)"
                                    >
                                        <template #preview>
                                            <BaseButton
                                                class="base-select-modal__date-preview"
                                                :active="getItemMultiIdDatepickerOpened(item)"
                                            >
                                                <span
                                                    :class="{'base-select-modal__date-icon_default': !getIsItemMultiDates(item)}"
                                                    class="base-select-modal__date-icon icon-tabler-icon-calendar-minus"
                                                />
                                                <span
                                                    :class="{'base-select-modal__date-title_default': !getIsItemMultiDates(item)}"
                                                    class="base-select-modal__date-title"
                                                >
                                                    {{getItemMultiPreviewDatepicker(item)}}
                                                </span>
                                            </BaseButton>
                                        </template>

                                        <template #content>
                                            <DatePicker
                                                :isMultiple="true"
                                                :accessDates="getItemMultiAccessDates(item)"
                                                :checkedDates="getItemMultiCheckedDates(item)"
                                                @approve="onApproveDateItemMulti($event, item)"
                                            />
                                        </template>
                                    </BaseDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script>
import BaseButton from './BaseButton.vue';
import BaseModal from './BaseModal.vue';
import BaseTextInput from './BaseTextInput.vue';
import BaseDropdown from './BaseDropdown.vue';
import DatePicker from './DatePicker.vue';
import UtilDate from '../../utils/UtilDate';
export default {
    name: 'BaseSelectModal',
    components: {
        BaseButton,
        BaseModal,
        BaseTextInput,
        BaseDropdown,
        DatePicker
    },
    props: {
        placeholderDefault: {
            type: String,
            default: 'Выберите значение'
        },
        title: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => ([])
        },
        // todo
        // itemSelected: {
        //     type: Object,
        //     default: () => ({})
        // },
        itemsSelected: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        isOpen: false,
        itemMultiIdDatepickerOpened: '',
        search: ''
    }),
    computed: {
        itemsFiltered() {
            return this.search === '' ? this.items : this.items.filter(item => String(item?.value ?? '').toLowerCase().includes(String(this.search).toLowerCase()));
        },
        itemsSimple() {
            return this.itemsFiltered.filter(item => String(item?.type ?? '') === 'simple');
        },
        itemsMulti() {
            return this.itemsFiltered.filter(item => String(item?.type ?? '') === 'multi');
        },
        itemsMultiGroups() {
            const itemsMultiGroups = this.itemsMulti.reduce((itemsMultiGroups, itemMulti) => {
                const itemMultiGroupKey = String(itemMulti?.groupKey ?? '');
                const itemMultiGroupLabel = String(itemMulti?.groupLabel ?? '');
                const index = itemsMultiGroups.findIndex(itemsMultiGroup => String(itemsMultiGroup?.groupKey ?? '') === itemMultiGroupKey);
                if (index === -1) {
                    itemsMultiGroups.push({
                        groupKey: itemMultiGroupKey,
                        groupLabel: itemMultiGroupLabel,
                        groupItems: [itemMulti]
                    });
                } else
                    itemsMultiGroups[index]?.groupItems.push(itemMulti);
                
                return itemsMultiGroups;
            }, []);

            return itemsMultiGroups;
        },
        itemsSelectedIds() {
            return this.itemsSelected.map(itemSelected => this.getItemSimpleId(itemSelected))
        },
        itemsSelectedDates() {
            return this.itemsSelected.map(itemSelected => ({id: this.getItemSimpleId(itemSelected), dates: itemSelected?.dates ?? []}))
        },
        itemsSelectedValues() {
            return this.itemsSelected.map(itemSelected => String(itemSelected?.value ?? ''));
        },
        isPreviewDefault() {
            return this.itemsSelectedValues.length === 0;
        },
        preview() {
            return this.isPreviewDefault ? this.placeholderDefault : this.itemsSelectedValues.join(', ');
        }
    },
    methods: {
        onToggle() {
            this.isOpen = !this.isOpen;
            this.itemMultiIdDatepickerOpened = '';

            this.$emit('onToggle', this.isOpen);
        },
        onToggleDatepicker(isDatepickerOpen = false, item = {}) {
            if (this.itemMultiIdDatepickerOpened !== '') {
                if (this.itemMultiIdDatepickerOpened === this.getItemMultiId(item)) {
                    this.itemMultiIdDatepickerOpened = '';
                }
                else if(isDatepickerOpen) {
                    this.itemMultiIdDatepickerOpened = this.getItemMultiId(item);
                }
            } else {
                this.itemMultiIdDatepickerOpened = this.getItemMultiId(item);
            }
        },
        onClickItemSimple(item = {}) {
            this.selectItemSimple(item);
            this.$emit('onSelectItem', item);
        },
        onApproveDateItemMulti(dates = [], item = {}) {
            const itemMulti = dates.length === 0 ? {} : {...item, dates: dates.map(date => UtilDate.fromDatepickerDate(date))};
            this.selectItemSimpleMulti(dates, item);
            this.$emit('onSelectItem', itemMulti);
        },
        selectItemSimple(item = {}) {
            let itemsSelectedBuff = [...this.itemsSelected];
            const index = itemsSelectedBuff.findIndex(itemSelected => String(itemSelected?.id ?? '') === String(item?.id ?? ''));
            if (index !== -1)
                itemsSelectedBuff.splice(index, 1);
            else
                itemsSelectedBuff.push(item);

            this.$emit('onSelectItems', itemsSelectedBuff);
        },
        selectItemSimpleMulti(dates = [], item = {}) {
            let itemsSelectedBuff = [...this.itemsSelected];
            const index = itemsSelectedBuff.findIndex(itemSelected => String(itemSelected?.id ?? '') === String(item?.id ?? ''));

            const itemMulti = {...item, dates: dates.map(date => UtilDate.fromDatepickerDate(date))};

            if (index !== -1) {
                if (dates.length > 0)
                    itemsSelectedBuff.splice(index, 1, itemMulti);
                else
                    itemsSelectedBuff.splice(index, 1);
            } else if (dates.length > 0)
                itemsSelectedBuff.push(itemMulti);
            
            this.$emit('onSelectItems', itemsSelectedBuff);
        },
        getItemSimpleId(item = {}) {
            return String(item?.id ?? '');
        },
        getItemSimpleValue(item = {}) {
            return String(item?.value ?? '');
        },
        getItemSimpleIsSelected(item = {}) {
            return this.itemsSelectedIds.includes(this.getItemSimpleId(item));
        },
        getItemMultiId(item = {}) {
            return this.getItemSimpleId(item);
        },
        getItemMultiValue(item = {}) {
            return this.getItemSimpleValue(item);
        },
        getItemMultiAccessDates(item = {}) {
            return item?.dates.map(date => UtilDate.toDatepickerDate(String(date))) ?? [];
        },
        getItemMultiCheckedDates(item = {}) {
            return this.getItemSelectedDates(item).map(date => UtilDate.toDatepickerDate(String(date)));
        },
        getItemMultiIdDatepickerOpened(item = {}) {
            return this.itemMultiIdDatepickerOpened === this.getItemMultiId(item);
        },
        getIsItemMultiDates(item = {}) {
            return this.getItemSelectedDates(item).length > 0;
        },
        getItemMultiPreviewDatepicker(item = {}) {
            const dates = this.getItemSelectedDates(item);
            return dates.length > 0 ? dates.map(date => UtilDate.getMonthsName(String(date))).join(', ') : 'Выберите дату';
        },
        getItemSelectedDates(item = {}) {
            const itemSelectedDates = this.itemsSelectedDates.find(itemSelectedDates => String(itemSelectedDates?.id) === this.getItemMultiId(item)) ?? {};
            return itemSelectedDates?.dates ?? [];
        },
        getItemMultiGroupKey(group = {}) {
            return String(group?.groupKey ?? '');
        },
        getItemMultiGroupLabel(group = {}) {
            return String(group?.groupLabel ?? '');
        },
        getItemMultiGroupItems(group = {}) {
            return group?.groupItems ?? [];
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-select-modal {
        &__button {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            &_default {
                color: #AAB0B8;
            }
        }

        &__title {
            font-size: 20px;
            font-weight: 500;
        }

        &__section {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &__action {
            &-list {
                display: flex;
            }

            &-item {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }

        &__list {
            display: flex;

            &-title {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 10px;
            }

            &-block {
                width: 50%;
                padding-right: 40px;
            }

            &-simple-item {
                padding: 8px 15px;
                border-radius: 10px;
                border: 1px solid var(--gray-light, #F5F5F5);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                cursor: pointer;
                color: var(--gray, #AAB0B8);
                
                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                &:hover, &_active {
                    background: var(--gray-light, #F5F5F5);
                    color: var(--black, #000);
                }
            }

            &-multi-item {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
                &-value {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    margin-bottom: 5px;
                }
            }

            &-group {
                border-radius: 10px;
                border: 1px solid var(--gray-light, #F5F5F5);
                padding: 20px;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }

        &__date {
            &-preview{
                width: 100%;
                display: flex;
                align-items: center;
                padding: 8px 15px;
            }

            &-icon {
                font-size: 18px;

                &_default {
                    color: #AAB0B8;
                }
            }

            &-title {
                margin-left: 10px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;

                &_default {
                    color: #AAB0B8;
                }
            }
        }
    }
</style>